import React, { useState, useEffect } from "react";
import "./ProgrammeStyles.scss";
import { getProgrammes } from "../../contentful";
import ProgrammeItem from "../../components/ProgrammeItem";

const Programme = () => {
  const [programmes, setProgrammes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [selectedCat, setSelectedCat] = useState(null);
  const [showingAll, setShowingAll] = useState(false);

  useEffect(() => {
    getProgrammes().then((data) => {
      console.log([...new Set(data.map((item) => item.fields.category))]);
      setProgrammes(data);
      setCategories([...new Set(data.map((item) => item.fields.category))]);
    });
  }, []);

  const showAll = () => {
    setFiltered(programmes);
    setShowingAll(true);
    setSelectedCat(null);
  };

  return (
    <div className='page-contents programme-page-contents'>
      {/* <div className='tiles-container'>
        {filtered.length === 0 &&
          categories.map((cat, i) => (
            <div
              className='category-tile'
              style={{
                padding: 2,
                margin: 30,
                borderRadius: 12,
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: 250,
              }}
              onClick={() => {
                setFiltered(
                  programmes.filter((p) => p.fields.category === cat)
                );
                setSelectedCat(cat);
              }}
            >
              <div>
                <h4 className='category-tile__label'>{cat}</h4>
              </div>
            </div>
          ))}
      </div> */}
      {/* <div
        style={{
          flexDirection: showingAll || filtered.length > 0 ? "row" : "column",
        }}
        className='buttons-container'
      >
        <button className='button' onClick={showAll}>
          Alle ansehen{" "}
        </button>
        <button
          className='button'
          onClick={() => {
            setFiltered([]);
            setSelectedCat(null);
            setShowingAll(false);
          }}
        >
          Clear{" "}
        </button>
      </div> */}
      {/* {selectedCat && (
        <div className='cat-header-light '>
          <div className='line' /> <h1>{selectedCat}</h1>{" "}
        </div>
      )} */}
      {/* {!showingAll &&
        filtered.map((prog) => <ProgrammeItem programme={prog.fields} />)}
      {showingAll && */}
      {categories.map((cat) => (
        <div>
          {/* <div className='cat-header-light'>
            <div className='line' />
            <h1>{cat}</h1>
          </div> */}
          {programmes
            .filter((p) => p.fields.category === cat)
            .map((p) => (
              <ProgrammeItem programme={p.fields} />
            ))}
        </div>
      ))}
    </div>
  );
};

export default Programme;
