import React, { useState, useEffect } from "react";
import "./PortraitStyles.scss";
import { getPortrait, getEnsemblePage } from "../../contentful";
import marked from "marked";
import HomeHero from "../../components/HomeHero";

const Portrait = () => {
  const [contents, setContents] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    getPortrait().then((data) => setContents(data[0].fields));
  }, []);

  return (
    <>
      <HomeHero />
      <div className='portrait-contents page-contents'>
        {/* <div className='portrait-contents__image'>
          {contents.image && (
            <img alt='ensemble' src={contents.image.fields.file.url} />
          )}
        </div> */}
        {contents.text && (
          <div className='portrait-contents__text main-container'>
            <div className='line' />
            <div
              className='body-text'
              dangerouslySetInnerHTML={{ __html: marked(contents.text) }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Portrait;
