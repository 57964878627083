import React from "react";
import "./ContactModalStyles.scss";
import Dialog from "@material-ui/core/Dialog";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Mail from "@material-ui/icons/Mail";
import Phone from "@material-ui/icons/Phone";

const ContactModal = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <div className='contact-container'>
        <IconButton
          style={{ position: "absolute", color: "gray", right: 16, top: 16 }}
          onClick={onClose}
        >
          <Close />
        </IconButton>
        <h2>Kontakt</h2>
        <p>
          Rudolf-Brenner-Strasse 40
          <br />
          D-70619 Stuttgart
        </p>
        <div className='contact-data-container'>
          <Phone />
          <p style={{ marginLeft: 16 }}> Tel.: +49 163 8787232</p>
        </div>
        <div className='contact-data-container'>
          <Mail />
          <a style={{ marginLeft: 16 }} href='mailto:	info@henning-wiegraebe.de'>
            info@henning-wiegraebe.de{" "}
          </a>
        </div>
      </div>
    </Dialog>
  );
};

export default ContactModal;
