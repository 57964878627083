const client = require("contentful").createClient({
  space: "don928vyxez7",
  accessToken: "RPaiCMZhk3O8xQVot8vcY28Pta79Pd4YcWc1s0HkWdQ",
});

const getNews = () =>
  client
    .getEntries({ content_type: "news" })
    .then((response) => response.items);

const getHomepageImage = () =>
  client
    .getEntries({ content_type: "homepageImage" })
    .then((response) => response.items);

const getHomepageVideo = () =>
  client
    .getEntries({ content_type: "homepageVideo" })
    .then((response) => response.items);

const getGalleryImages = () =>
  client
    .getEntries({ content_type: "galleryImage" })
    .then((response) => response.items);

const getYoutube = () =>
  client
    .getEntries({ content_type: "video" })
    .then((response) => response.items);

const getEnsemblePage = () =>
  client
    .getEntries({ content_type: "ensembleText" })
    .then((response) => response.items);

const getRecordings = () =>
  client
    .getEntries({ content_type: "recording", order: "fields.order" })
    .then((response) => response.items);

const getProgrammes = () =>
  client
    .getEntries({ content_type: "programme" })
    .then((response) => response.items);

const getPortrait = () =>
  client
    .getEntries({ content_type: "portrait" })
    .then((response) => response.items);

const getSingleProgramme = (slug) =>
  client
    .getEntries({ content_type: "programme", "sys.id": slug })
    .then((response) => response.items);

const getSinglePost = (slug) =>
  client
    .getEntries({
      "sys.id": slug,
      content_type: "observationJournal",
    })
    .then((response) => response.items);

const getImpressum = () =>
  client
    .getEntries({ content_type: "impressum" })
    .then((response) => response.items);

export {
  getImpressum,
  getPortrait,
  getProgrammes,
  getSingleProgramme,
  getSinglePost,
  getNews,
  getGalleryImages,
  getYoutube,
  getRecordings,
  getEnsemblePage,
  getHomepageVideo,
  getHomepageImage,
};
